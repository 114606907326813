import React, { useState, useEffect } from 'react';
import './ChatListItem.css';

export default ({onClick, active, data}) => {
    const [time, setTime] = useState('');

    useEffect(()=>{
        if(data.lastMessageDate > 0) {
            let d = new Date(data.lastMessageDate.seconds * 1000);
            let day = d.getDate();
            let month = d.getMonth()+1;
            let year = d.getFullYear();
            let hours = d.getHours();
            let minutes = d.getMinutes();
            hours = hours < 10 ? '0'+hours : hours;
            minutes = minutes < 10 ? '0'+minutes : minutes;
            setTime(`${day}/${month}/${year} - ${hours}:${minutes}`);
        }
    }, [data]);

    return (
        <div
         className={`chatListItem ${active?'active':''}`}
         onClick={onClick}
        >
            <img className="chatListItem--avatar" src={'https://cdn-icons-png.flaticon.com/512/74/74472.png'} alt="" />
            <div className="chatListItem--lines">
                <div className="chatListItem--line">
                    <div className="chatListItem--name">{data.title}</div>
                    <div className="chatListItem--date">{time}</div>
                </div>
                <div className="chatListItem--line">
                    <div className="chatListItem--lastMsg">
                        <p>{data.subject}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}