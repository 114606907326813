import firebase from 'firebase/app';
import 'firebase/firebase-auth';
import 'firebase/firebase-firestore';

import firebaseConfig from './firebaseConfig';

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

export default {
    fbPopup:async () => {
        const provider = new firebase.auth.FacebookAuthProvider();
        let result = await firebaseApp.auth().signInWithPopup(provider);
        return result;
    },
    login:async (email, password) => {
        var usersRef = db.collection("users");
        var user;
        var res = await usersRef.where("email", "==", email).limit(1).get();
        user = res.docs[0].data();
        user.id = res.docs[0].id;

        if(user.password == password) {
            return user;
        }

    },
    addUser:async (u) => {
        await db.collection('users').doc(u.id).set({
            name: u.name,
            avatar: u.avatar
        }, {merge:true});
    },
    getInfosUser:async (userId, setUserInfos) => {
        return db.collection('users').doc(userId).onSnapshot((doc)=>{
            if(doc.exists) {
                let data = doc.data();
                setUserInfos(data);
            }
        });
    },
    getContactList:async (userId) => {
        let list = [];

        let results = await db.collection('users').get();
        results.forEach(result => {
            let data = result.data();

            if(result.id !== userId) {
                list.push({
                    id: result.id,
                    name: data.name,
                    avatar: data.avatar
                });
            }
        });

        return list;
    },
    addNewChat:async (user, user2) => {
        let newChat = await db.collection('chats').add({
            messages: [],
            users: [user.id, user2.id],
        });

        db.collection('users').doc(user.id).update({
            chats: firebase.firestore.FieldValue.arrayUnion({
                chatId: newChat.id,
                title: user2.name,
                image: user2.avatar,
                with: user2.id
            })
        });

        db.collection('users').doc(user2.id).update({
            chats: firebase.firestore.FieldValue.arrayUnion({
                chatId: newChat.id,
                title: user.name,
                image: user.avatar,
                with: user.id
            })
        });
    },
    onChatList: (userId, setChatList) => {
        return db.collection('users').doc(userId).onSnapshot((doc)=>{
            if(doc.exists) {
                let data = doc.data();
                if(data.chats) {

                    let chats = [...data.chats];
                    chats.sort((a,b)=>{
                        if(a.lastMessageDate === undefined) {
                            return -1;
                        }
                        if(b.lastMessageDate === undefined) {
                            return -1;
                        }
                        if(a.lastMessageDate.seconds < b.lastMessageDate.seconds) {
                            return 1;
                        } else {
                            return -1;
                        }
                    });

                    setChatList(chats);
                }
            }
        });
    },
    onAllChatList: (setChatList) => {
        return db.collection('users').where("type", "==", "visitor").onSnapshot((doc)=>{
            var users = [];
            doc.forEach((doc) => {
                users.push(doc.data());
            });

            let chats = [];
            users.forEach((user) => {
                if(user.chats) {
                    chats = [...chats, user.chats[0]];
                    chats.sort((a,b)=>{
                        if(a.lastMessageDate === undefined) {
                            return -1;
                        }
                        if(b.lastMessageDate === undefined) {
                            return -1;
                        }
                        if(a.lastMessageDate.seconds < b.lastMessageDate.seconds) {
                            return 1;
                        } else {
                            return -1;
                        }
                    });

                }
            });
            setChatList(chats);

        });
    },
    onChatContent: (chatId, setList, setUsers, setChatId) => {
        return db.collection('chats').doc(chatId).onSnapshot((doc)=>{
            if(doc.exists) {
                let data = doc.data();
                setList(data.messages);
                setUsers(data.users);
                setChatId(doc.id);
            }
        });
    },
    sendMessage:async (chatData, userId, type, body, users) => {
        let now = new Date();

        db.collection('chats').doc(chatData.chatId).update({
            messages: firebase.firestore.FieldValue.arrayUnion({
                type,
                author: userId,
                body,
                date: now,
                visitor: false
            })
        });

        for(let i in users) {
            let u = await db.collection('users').doc(users[i]).get();
            let uData = u.data();
            if(uData.chats) {
                let chats = [...uData.chats];

                for(let e in chats) {
                    if(chats[e].chatId == chatData.chatId) {
                        chats[e].lastMessage = body;
                        chats[e].lastMessageDate = now;
                    }
                }

                await db.collection('users').doc(users[i]).update({
                    chats
                });
            }
        }

    },
    deleteChat: async (chatId, userId) => {
        console.log(userId);
        if(chatId){
            db.collection("chats").doc(chatId).delete()
        }
        
        let u = await db.collection('users').doc(userId).get();
        console.log(u.data());
        let uData = u.data();
        
        if(uData.chats) {
            let chats = [...uData.chats];

            chats[0].lastMessage = '';
            chats[0].lastMessageDate = '';

            await db.collection('users').doc(userId).update({
                chats
            });
        }
        return true;
    },
    deleteUser: (chatId, userId) => {
        if(chatId) {
            db.collection("chats").doc(chatId).delete()
        }
        if(userId) {
            db.collection("users").doc(userId).delete()
        }
        return true;
    }
};