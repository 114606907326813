import React, { useState, useEffect } from 'react';
import './MessageItem.css';

export default ({data, user}) => {

    const [time, setTime] = useState('');

    useEffect(()=>{
        if(data.date > 0) {
            let d = new Date(data.date.seconds * 1000);
            let day = d.getDate();
            let month = d.getMonth()+1;
            let year = d.getFullYear();
            let hours = d.getHours();
            let minutes = d.getMinutes();
            hours = hours < 10 ? '0'+hours : hours;
            minutes = minutes < 10 ? '0'+minutes : minutes;
            setTime(`${day}/${month}/${year} - ${hours}:${minutes}`);
        }
    }, [data]);

    return (
        <div className="messageLine" style={{justifyContent: data.visitor === false ? 'flex-end' : 'flex-start'}} >
            <div className="messageItem" style={{backgroundColor: data.visitor === false ? '#DCF8C6' : '#FFF'}}>
                <div className="messageText">{data.body}</div>
                <div className="messageDate">{time}</div>
            </div>
        </div>
    );
}