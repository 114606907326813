import React, { useState } from 'react';
import './Login.css';
import Api from '../Api';

export default ({onReceive}) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleOnKeyPress = (e) => {
        if(e.key === 'Enter' && email && password) {
            handleLogin();
        }
    }

    const handleLogin = async () => {
        if(email && password) {
            setMessage('');
            let result = await Api.login(email, password);
            if(result) {
                onReceive(result);
            } else {
                setMessage("Email e/ou senha incorretos!");
            }
        } else {
            alert("Preencha os campos!");
        }
    }

    return (
        <div className="login">
            <div className="cardLogin">
                <div className="header">
                    <img src={'logo192.png'} />
                    <h3>Appie <br/>Desenvolvimentos</h3>
                </div>
                <div className="loginContent">
                    <h1>Login</h1>
                    <input 
                        type={"email"}
                        placeholder="Digite o Email"
                        value={email}
                        onChange={e=>setEmail(e.target.value)}
                    />
                    <input 
                        type={"password"}
                        placeholder="Digite a Senha"
                        value={password}
                        onChange={e=>setPassword(e.target.value)}
                        onKeyPress={(e) => handleOnKeyPress(e)}
                    />
                    <button onClick={handleLogin}>Logar</button>
                    <span>{message}</span>
                </div>
            </div>
        </div>
    )
}