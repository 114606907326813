import React, { useState, useEffect, useRef } from 'react';
import './ChatWindow.css';
import EmojiPicker from 'emoji-picker-react';

import Api from '../Api';

import MessageItem from './MessageItem';

import SearchIcon from '@material-ui/icons/Search';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import MicIcon from '@material-ui/icons/Mic';

export default ({user, data}) => {

    const body = useRef();

    let recognition = null;
    let SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if(SpeechRecognition !== undefined) {
        recognition = new SpeechRecognition();
    }

    const [emojiOpen, setEmojiOpen] = useState(false);
    const [text, setText] = useState('');
    const [listening, setListening] = useState(false);
    const [list, setList] = useState([]);
    const [users, setUsers] = useState([]);
    const [userInfos, setUserInfos] = useState([]);
    const [chatId, setChatId] = useState('');
    const [showOptions, setShowOptions] = useState(false);
    console.log(userInfos);

    useEffect(()=>{

        setList([]);
        let unsub = Api.onChatContent(data.chatId, setList, setUsers, setChatId);
        return unsub;
    }, [data.chatId]);

    useEffect(()=>{
        setUserInfos([]);
        let unsub = Api.getInfosUser(users[0], setUserInfos);
        return unsub;
    }, [users]);

    useEffect(()=>{
        if(body.current.scrollHeight > body.current.offsetHeight) {
            body.current.scrollTop = body.current.scrollHeight - body.current.offsetHeight;
        }
    }, [list]);

    const handleEmojiClick = (e, emojiObject) => {
        setText( text + emojiObject.emoji );
    }

    const handleOpenEmoji = () => {
        setEmojiOpen(true);
    }
    const handleCloseEmoji = () => {
        setEmojiOpen(false);
    }

    const handleMicClick = () => {

        if(recognition !== null) {
            recognition.onstart = () => {
                setListening(true);
            }
            recognition.onend = () => {
                setListening(false);
            }
            recognition.onresult = (e) => {
                setText( e.results[0][0].transcript );
            }

            recognition.start();
        }
    }
    const handleInputKeyUp = (e) => {
        if(e.keyCode == 13) {
            handleSendClick();
        }
    }
    const handleSendClick = () => {
        if(text !== '') {
            Api.sendMessage(data, user.id, 'text', text, users);
            setText('');
            setEmojiOpen(false);
        }
    }

    const handleOptions = () => {
        setShowOptions(!showOptions);
    }

    const handleDeleteChat = () => {
        window.confirm( 'Tem certeza que deseja deletar este Chat?', ) && confirmDeleteChat();
    }

    const confirmDeleteChat = async () => {
        const res = await Api.deleteChat(chatId, users[0]);
        console.log(res);
        if(res) {
            setList([]);
        }
    }

    const handleDeleteUser = () => {
        window.confirm( 'Tem certeza que deseja deletar este Usuário?', ) && confirmDeleteUser();
    }

    
    const confirmDeleteUser = async () => {
        const res = await Api.deleteUser(chatId, users[0]);
        console.log(res);
        if(res) {
            setList([]);
            setUsers([]);
        }
    }


    return (
        <div className="chatWindow">
            <div className="chatWindow--header">
                <div className="chatWindow--headerinfo">
                    <img className="chatWindow--avatar" src={'https://cdn-icons-png.flaticon.com/512/74/74472.png'} alt="" />
                    <div className="chatWindow--infos">
                        <div className="chatWindow--name">{data.title} - {userInfos.email}</div>
                        <div className="chatWindow--phone"><a href={"https://wa.me/"+userInfos.phone} target="_blank">{userInfos.phone}</a></div>
                    </div>
                </div>

                <div className="chatWindow--headerbuttons">
                    <div className="chatWindow--btn">
                        <SearchIcon style={{color: '#919191'}} />
                    </div>
                    <div className="chatWindow--btn">
                        <AttachFileIcon style={{color: '#919191'}} />
                    </div>
                    <div className="chatWindow--btn" onClick={handleOptions} >
                        <MoreVertIcon style={{color: '#919191'}} />
                    </div>
                </div>

                <div className="options--body" style={{display: showOptions ? 'block' : 'none'}} >
                    <div className="options--option" onClick={handleDeleteChat} >
                        <span>Excluir Chat</span>
                    </div>
                    <div className="options--option" onClick={handleDeleteUser} >
                        <span>Excluir Usuário</span>
                    </div>
                </div>
            </div>

            

            <div ref={body} className="chatWindow--body">
                {list.map((item, key)=>(
                    <MessageItem 
                        key={key}
                        data={item}
                        user={user}
                    />
                ))}
            </div>

            <div className="chatWindow--emojiarea" style={{height: emojiOpen? '200px' : '0px'}} >
                <EmojiPicker 
                    onEmojiClick={handleEmojiClick}
                    disableSearchBar
                    disableSkinTonerPicker
                />
            </div>

            <div className="chatWindow--footer">
                <div className="chatWindow--pre">

                    <div className="chatWindow--btn" onClick={handleCloseEmoji} style={{width: emojiOpen?40:0}} >
                        <CloseIcon style={{color: '#919191'}} />
                    </div>
                    <div className="chatWindow--btn" onClick={handleOpenEmoji}>
                        <InsertEmoticonIcon  style={{color: emojiOpen?'#009688':'#919191'}} />
                    </div>


                </div>

                <div className="chatWindow--inputarea">
                    <input 
                        className="chatWindow--input" 
                        type="text" 
                        placeholder="Digite uma mensagem" 
                        value={text} 
                        onChange={e=>setText(e.target.value)}
                        onKeyUp={handleInputKeyUp}
                    />
                </div>

                <div className="chatWindow--pos">
                    
                    {text === '' &&
                    <div onClick={handleMicClick} className="chatWindow--btn">
                        <MicIcon style={{color: listening ? '#126ECE' :'#919191'}} />
                    </div>
                    }
                    {text !== '' &&
                    <div onClick={handleSendClick} className="chatWindow--btn">
                        <SendIcon style={{color: '#919191'}} />
                    </div>
                    }
                </div>
            </div>
        </div>
    );
}