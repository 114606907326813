import React from 'react';
import './ChatIntro.css';

export default () => {
    return (
        <div className="chatIntro">
            <img src="https://fujiokadigital.com/blog/wp-content/uploads/whatsapp-web.png" alt="" />
            <h1>Appie Desenvolvimentos</h1>
            <h2>Área de suporte para chat do Site</h2>
        </div>
    )
}